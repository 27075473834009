import React from "react";
import Experience from "./experience";

export default function ExperienceList({ experiences }) {
  return (
    <>
      <h2>Experience</h2>
      {experiences.map((xp, i) => {
        return <Experience experience={xp} key={i} />;
      })}
    </>
  );
}
