import React from "react";
import { Container } from "react-bootstrap";
import { mainColor, backgroundWhite } from "../shared/layout.module.css";

export default function Certification({ certification }) {
  return (
    <div>
      <Container className={`mt-3 pt-3 pb-3 border rounded ${backgroundWhite}`}>
        <h3 className={mainColor}>{certification.name}</h3>
        <small className="text-secondary">{certification.institution}</small>
        {certification.url ? (
          <div style={{ overflow: "hidden" }}>
            <a href={certification.url} rel="noreferrer" target="_blank">
              {certification.url}
            </a>
          </div>
        ) : null}
      </Container>
    </div>
  );
}
