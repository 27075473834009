import React from "react";
import Certification from "./certification";

export default function CertificationList({ certifications }) {
  return (
    <div className="pt-2">
      <h2>Certification</h2>
      {certifications.map((c, i) => (
        <Certification certification={c} key={i} />
      ))}
    </div>
  );
}
