import React from "react";
import Education from "./education";

export default function EducationList({ educations }) {
  return (
    <div className="pt-2">
      <h2>Education</h2>
      {educations.map((e, i) => (
        <Education education={e} key={i} />
      ))}
    </div>
  );
}
