import React from "react";
import { Accordion, Container, Badge } from "react-bootstrap";
import { mainColor, backgroundWhite } from "../shared/layout.module.css";

export default function Experience({ experience }) {
  return (
    <Container className={`mt-3 pt-3 pb-3 border rounded ${backgroundWhite}`}>
      <h3 className={`${mainColor}`}>{experience.title}</h3>
      <small className="text-secondary">
        {experience.from} - {experience.to}
      </small>
      <div className="pt-1">
        <small>
          <b>{experience.company}</b>
        </small>
      </div>
      <div className="pt-1 pb-2">{experience.description}</div>
      <div>
        {experience.tags.split(",").map((t, i) => (
          <Badge bg="secondary" className="m-1" size="sm" key={i}>
            {t}
          </Badge>
        ))}
      </div>
      <Accordion className="pt-1" flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <span className={mainColor}>Additional information</span>
          </Accordion.Header>
          <Accordion.Body>{experience.additional_information}</Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
}
