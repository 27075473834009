import React from "react";
import { Container } from "react-bootstrap";
import { mainColor, backgroundWhite } from "../shared/layout.module.css";

export default function Education({ education }) {
  return (
    <div>
      <Container className={`mt-3 pt-3 pb-3 border rounded ${backgroundWhite}`}>
        <h3 className={mainColor}>{education.name}</h3>
        <small className="text-secondary">
          {education.from} - {education.to}
        </small>
        <div>
          <b>{education.institution}</b>
        </div>
      </Container>
    </div>
  );
}
