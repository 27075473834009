import React from "react";
import CertificationList from "../../components/certificationList";
import EducationList from "../../components/educationList";
import ExperienceList from "../../components/experienceList";
import Layout from "../../components/layout";
import data from "./data.json";

export default function Curriculum() {
  return (
    <Layout
      pageTitle="Curriculum - Resume of Daniel José Da Silva"
      description="Curriculum - Resume of Daniel José Da Silva"
    >
      <ExperienceList experiences={data.experiences} />
      <EducationList educations={data.educations} />
      <CertificationList certifications={data.certifications} />
      <br />
    </Layout>
  );
}
